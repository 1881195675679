import { render, staticRenderFns } from "./AIfallbacks.vue?vue&type=template&id=72351009&scoped=true&"
import script from "./AIfallbacks.vue?vue&type=script&lang=js&"
export * from "./AIfallbacks.vue?vue&type=script&lang=js&"
import style0 from "./AIfallbacks.vue?vue&type=style&index=0&id=72351009&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72351009",
  null
  
)

export default component.exports
import {QCard,QCardSection,QInnerLoading,QSpinnerBars,QSelect,QItem,QItemSection,QItemLabel,QAvatar,QTable,QTr,QTh,QTd,QSpinnerComment,QImg,QTooltip} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QInnerLoading,QSpinnerBars,QSelect,QItem,QItemSection,QItemLabel,QAvatar,QTable,QTr,QTh,QTd,QSpinnerComment,QImg,QTooltip})
